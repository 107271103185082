/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/no-misused-promises */
import React, { useEffect, useState } from 'react';
import {
  FormProvider, useForm, useFormContext,
} from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Accordion } from 'react-bootstrap';
import clsx from 'clsx';
import { ProvisionalSavingsSimulationValues, provisionalSavingsValidationSchema, useProvisionalSavings } from './useProvisionalSavings';
import {
  InputField,
  NumericFormatField, RadioCardField, RadioField, SelectField,
  DatepickerField,
} from '../../Forms/Fields';
import {
  BooleanOptions,
  ExtraordinaryContributionOptions,
  GenderOptionType,
  Genders,
  ApvTransferOptions,
  Regime,
  Regimes,
  RiskProfiles,
  SavingsTypeOptions,
  StringifiedBooleans,
  DISCOVER_INVESTMENT_PROFILE_URL,
} from '../../../utils/constants';
import { getParameterContext } from '../../../utils/parameters';
import { useRegimeRecommendation } from '../../../hooks/useRegimeRecommendation';
import { Chip } from '../../common/Chip';
import { useResetSavingOption } from '../../../hooks/useResetSavingsOption';
import { RegimeHelpPopover, SavingsCapacityPopover, SavingsCapacityTooltipContent } from '../../common/Popover';
import { RadioBoxField } from '../../Forms/Fields/RadioBoxField';
import { APVTransferField } from '../sharedFields/APVTransferField';
import { handleRutChange } from '../../../utils/formHandlers';
import { mapQueryParamsToRecommendationPayload } from '../../RecommenderForms/common/queryParams';
import { SavingsExceededModal } from '../../SavingsExceededModal';
import { useSavingsExceededModal } from '../../SavingsExceededModal/useSavingsExceededModal';
import {
  MIN_RETIREMENT_AGE_BY_GENDER,
  validateAPVTransfer,
  validateSavingsOption,
} from '../../../utils/validations';
import { getYearsDiffFromNow } from '../../../utils/dates';
import { ExtraordinaryContributionFields } from '../sharedFields/ExtraordinaryContribution';
import { useResetAPVTransfer } from '../../../hooks/useResetAPVTransfer';

function checkClientDataAccordionFilled() {
  const form = useFormContext<ProvisionalSavingsSimulationValues>();
  const {
    rut,
    date_of_birth,
    retirement_age,
    change_retirement_age,
    gender,
  } = form.watch();

  const isFilledWithBaseFields = [rut, date_of_birth, gender].every(Boolean);
  const isFilledWithRetirementAge = [rut, date_of_birth, gender, retirement_age].every(Boolean);

  return change_retirement_age === 'TRUE' ? isFilledWithRetirementAge : isFilledWithBaseFields;
}

function checkOtherDataAccordionFilled() {
  const form = useFormContext<ProvisionalSavingsSimulationValues>();
  const {
    savings_option,
    ideal_pension,
    net_salary,
    afp_balance,
    apv_transfer_option,
    source_transfer,
    contribution_amount,
  } = form.watch();

  return validateSavingsOption(savings_option, 'ideal_pension', ideal_pension)
  && validateAPVTransfer(
    apv_transfer_option,
    contribution_amount,
    source_transfer?.value
  )
  && [net_salary, afp_balance].every(Boolean);
}

function ClientDataAccordion() {
  const enableRetirementAge = getParameterContext('ENABLE_RETIREMENT_AGE');
  const form = useFormContext<ProvisionalSavingsSimulationValues>();
  const [exceedsMaxAgeByGender, setExceedsMaxAgeByGender] = useState(false);

  const {
    savings_option,
    change_retirement_age,
    date_of_birth,
    gender,
  } = form.watch();

  useResetSavingOption<ProvisionalSavingsSimulationValues>({
    idealPensionPath: 'ideal_pension',
    monthlySavingsPath: 'monthly_savings',
    savingOption: savings_option,
    setValue: form.setValue,
  });

  const isFilled = checkClientDataAccordionFilled();

  // Computes the age by the given date of birth and then automatically
  // Forces the change retirement input state if age exceeds the default
  // retirement age for selected gender.
  useEffect(() => {
    // Since the logic is dispatched each time that user change date,
    // we need to set a maximum years threshold to change the retirement
    // age only if user completes the date input.
    const YEARS_THRESHOLD = 110;
    const currentAge = getYearsDiffFromNow(date_of_birth);

    if (
      !Number.isNaN(currentAge)
      && currentAge > MIN_RETIREMENT_AGE_BY_GENDER[gender as GenderOptionType]
      && currentAge <= YEARS_THRESHOLD) {
      form.setValue('change_retirement_age', 'TRUE');
      setExceedsMaxAgeByGender(true);
      return;
    }

    setExceedsMaxAgeByGender(false);
    form.setValue('change_retirement_age', 'FALSE');
  }, [gender, date_of_birth]);

  useEffect(() => {
    form.clearErrors('date_of_birth');
  }, [gender]);

  useEffect(() => {
    if (change_retirement_age === 'FALSE') {
      form.resetField('retirement_age');
    }
  }, [change_retirement_age]);

  // Clears minimum retirement age error when user changes the gender input.
  // The idea is not to keep the stale error for a new selected gender;
  // so if gender changes, error is cleared
  useEffect(() => {
    form.clearErrors('retirement_age');
  }, [gender]);

  return (
    <Accordion.Item className="shadow-none" eventKey="0">
      <Accordion.Button className={clsx(isFilled && 'bg-success-light')}>
        Datos del cliente
      </Accordion.Button>

      <Accordion.Body className="d-flex flex-column gap-2">
        <RadioBoxField<ProvisionalSavingsSimulationValues>
          options={SavingsTypeOptions
            .asRadioBoxOptions({ select: ['savings_capacity', 'ideal_pension'] })}
          path="savings_option"
          label="¿Cómo te gustaría ahorrar?"
        />

        <InputField<ProvisionalSavingsSimulationValues>
          id="rut"
          label="¿Cuál es tu RUT?"
          path="rut"
          type="text"
          placeholder="11.111.111-1"
          onChange={handleRutChange}
        />

        <DatepickerField
          id="date_of_birth"
          label="¿Cuál es tu fecha de nacimiento?"
          path="date_of_birth"
        />

        <RadioField<ProvisionalSavingsSimulationValues>
          label="¿Cuál es tu género?"
          options={Genders.asRadioOptions({ omit: ['OTHER'] })}
          path="gender"
        />

        {
          enableRetirementAge && (
            <>
              <RadioField<ProvisionalSavingsSimulationValues>
                label="¿Quieres cambiar tu edad de jubilación?"
                path="change_retirement_age"
                options={BooleanOptions.asRadioOptions()}
              />
              {
                change_retirement_age === 'TRUE' && (
                  <NumericFormatField<ProvisionalSavingsSimulationValues>
                    id="retirement_age"
                    path="retirement_age"
                    placeholder="Ingresa la edad"
                    suffix=" años"
                    helpMessage={exceedsMaxAgeByGender ? 'Supera la edad de jubilación por defecto. Especifica otra edad.' : undefined}
                  />
                )
              }
            </>
          )
        }
      </Accordion.Body>
    </Accordion.Item>
  );
}

interface OtherDataAccordionProps {
  savingsCapacityTooltipContent: SavingsCapacityTooltipContent
  handleGetRecommendedRegime: (netSalary: number) => Promise<void>
  handleShowSavingsExceededModal: () => void
  optionalIncomeReduction: number
}

function OtherDataAccordion({
  savingsCapacityTooltipContent,
  handleGetRecommendedRegime,
  handleShowSavingsExceededModal,
  optionalIncomeReduction,
}: OtherDataAccordionProps) {
  const form = useFormContext<ProvisionalSavingsSimulationValues>();

  const {
    savings_option,
    monthly_savings,
    apv_transfer_option,
    apv_regime,
  } = form.watch();

  const shouldDisplaySavingsCapacityPopover = apv_regime === 'B' && optionalIncomeReduction > 0;

  const canOpen = checkClientDataAccordionFilled();
  const isFilled = checkOtherDataAccordionFilled();

  useResetAPVTransfer<ProvisionalSavingsSimulationValues>({
    contributionAmountPath: 'contribution_amount',
    contributionOption: apv_transfer_option,
    transferCompanyPath: 'source_transfer',
    setValue: form.setValue,
  });

  return (
    <Accordion.Item className="shadow-none" eventKey="1">
      <Accordion.Button className={clsx(isFilled && 'bg-success-light')} disabled={!canOpen}>
        Otros datos
      </Accordion.Button>
      <Accordion.Body className="d-flex flex-column gap-2">
        {
          savings_option === 'savings_capacity' && (
          <NumericFormatField<ProvisionalSavingsSimulationValues>
            id="monthly_savings"
            label="¿Cuál es tu capacidad de ahorro mensual?"
            path="monthly_savings"
            onBlur={handleShowSavingsExceededModal}
            prefix="$ "
            placeholder="$"
            renderHelpPopover={
              shouldDisplaySavingsCapacityPopover
                ? () => (
                  <SavingsCapacityPopover
                    expectedMonthlySavings={optionalIncomeReduction}
                    liquidityDecrement={monthly_savings}
                    tooltipContent={savingsCapacityTooltipContent}
                  />
                ) : undefined
              }
          />
          )
        }

        {
          savings_option === 'ideal_pension' && (
          <NumericFormatField<ProvisionalSavingsSimulationValues>
            id="ideal_pension"
            label="¿Cuál es tu pensión ideal? (monto bruto)"
            path="ideal_pension"
            prefix="$ "
            placeholder="$"
          />
          )
        }

        <NumericFormatField<ProvisionalSavingsSimulationValues>
          id="net_salary"
          label="¿Cuál es tu sueldo líquido?"
          path="net_salary"
          prefix="$ "
          placeholder="$"
          onBlur={(value) => handleGetRecommendedRegime(value as number)}
        />

        <NumericFormatField<ProvisionalSavingsSimulationValues>
          id="afp_balance"
          label="¿Cuál es tu saldo en AFP? (Cuenta obligatoria)"
          path="afp_balance"
          prefix="$ "
          placeholder="$"
        />

        <SelectField<ProvisionalSavingsSimulationValues>
          id="risk_profile"
          path="risk_profile"
          renderLabel={() => (
            <span>
              {'¿Cuál es tu perfil inversionista? '}
              <span className="fw-normal">
                {'Si no sabes revisa '}
              </span>
              <a href={DISCOVER_INVESTMENT_PROFILE_URL} target="_blank" className="fw-bold" rel="noreferrer">aquí</a>
            </span>
          )}
          options={
            RiskProfiles
              .asSelectOptionsWithPercentage<ProvisionalSavingsSimulationValues>('apv').choices
          }
        />

        <APVTransferField<ProvisionalSavingsSimulationValues>
          contributionOption={apv_transfer_option}
          setValue={form.setValue}
          contributionAmountPath="contribution_amount"
          contributionOptionPath="apv_transfer_option"
          transferCompanyPath="source_transfer"
        />
      </Accordion.Body>
    </Accordion.Item>
  );
}

function ExtraordinaryContributionAccordion() {
  const canOpen = checkClientDataAccordionFilled() && checkOtherDataAccordionFilled();

  return (
    <Accordion.Item className="shadow-none" eventKey="2">
      <Accordion.Button disabled={!canOpen} className={clsx(canOpen && 'bg-success-light')}>
        Aportes extraordinarios (Opcional)
      </Accordion.Button>

      <Accordion.Body className="d-flex flex-column gap-2">
        <ExtraordinaryContributionFields<ProvisionalSavingsSimulationValues>
          id="provisional-savings-extraordinary-contribution"
          uniqueContributionKind="extraordinary_contribution.initial_contribution_kind"
          uniqueContributionValue="extraordinary_contribution.initial_contribution"
          agreedDepositKind="extraordinary_contribution.agreed_deposits_kind"
          agreedDepositValue="extraordinary_contribution.agreed_deposits"
        />
      </Accordion.Body>
    </Accordion.Item>
  );
}

export function ProvisionalSavingsForm() {
  const [repeatSimulation, setRepeatSimulation] = useState(false);
  const [
    savingsCapacityTooltipContent,
    setSavingsCapacityTooltipContent,
  ] = useState<SavingsCapacityTooltipContent>('HELP');

  // Load query parameters and tries to fill the default values based on them
  const searchParams = new URLSearchParams(window.location.search);
  const valuesFromRecommender = mapQueryParamsToRecommendationPayload(searchParams);

  const methods = useForm<ProvisionalSavingsSimulationValues>({
    defaultValues: {
      gender: valuesFromRecommender.gender,
      // Tries to get the non default option (ideal pension) from query parameters.
      // If it is not present, automatically select the default saving option (savings capacity)
      savings_option: valuesFromRecommender.ideal_pension > 0 ? 'ideal_pension' : 'savings_capacity',
      monthly_savings: valuesFromRecommender.monthly_savings ?? undefined,
      ideal_pension: valuesFromRecommender.ideal_pension,
      date_of_birth: valuesFromRecommender.date_of_birth,
      net_salary: valuesFromRecommender.net_income,
      apv_transfer_option: valuesFromRecommender.additional_savings[0] === 'apv'
        ? ApvTransferOptions.TRANSFER
        : ApvTransferOptions.NONE,
      extraordinary_contribution: {
        agreed_deposits_kind: ExtraordinaryContributionOptions.UNIQUE,
        agreed_deposits: 0,
        initial_contribution_kind: ExtraordinaryContributionOptions.UNIQUE,
        initial_contribution: 0,
      },
      apv_regime: '',
      contribution_amount: 0,
      change_retirement_age: StringifiedBooleans.FALSE,
      retirement_age: null,
      risk_profile: RiskProfiles.asSelectOptionsWithPercentage('apv').defaultChoice,
      source_transfer: null,
    },
    resolver: zodResolver(provisionalSavingsValidationSchema),
  });

  const {
    apv_regime,
    savings_option,
    monthly_savings,
    net_salary,
  } = methods.watch();

  const { handleSubmitSimulation, optionalIncomeReduction } = useProvisionalSavings();
  const { recommendedRegime, handleGetRecommendedRegime } = useRegimeRecommendation(net_salary);
  const {
    show: showSavingsExceededModal,
    handleShow: handleShowSavingsExceededModal,
    handleClose: handleCloseSavingsExceededModal,
  } = useSavingsExceededModal({
    currency: 'CLP',
    regime: apv_regime as Regime,
    savingsAmount: monthly_savings,
  });

  const handleTriggerErrors = () => methods.formState.errors;

  const onSubmit = async (values: ProvisionalSavingsSimulationValues) => {
    await handleSubmitSimulation(values);
    setRepeatSimulation(true);
    // Restarts the tooltip content each time simulation is submitted successfully
    setSavingsCapacityTooltipContent('HELP');
  };

  // Displays the retry content for savings capacity simulation when savings option
  // or monthly savings fields change
  useEffect(() => {
    setSavingsCapacityTooltipContent('RETRY');
  }, [savings_option, monthly_savings]);

  return (
    <FormProvider {...methods}>
      <form className="d-flex flex-column gap-4" onSubmit={methods.handleSubmit(onSubmit)}>
        <Accordion className="accordion-form accordion-pills" defaultActiveKey="0">
          <ClientDataAccordion />
          <OtherDataAccordion
            savingsCapacityTooltipContent={savingsCapacityTooltipContent}
            handleGetRecommendedRegime={handleGetRecommendedRegime}
            handleShowSavingsExceededModal={handleShowSavingsExceededModal}
            optionalIncomeReduction={optionalIncomeReduction}
          />
          <ExtraordinaryContributionAccordion />
        </Accordion>

        <RadioCardField<ProvisionalSavingsSimulationValues>
          label="¿Bajo qué Régimen quieres invertir?"
          options={Regimes.asRadioCardOptions()}
          path="apv_regime"
          renderPopover={() => <RegimeHelpPopover />}
          cardWrapper={(card) => (
            <div className="position-relative w-100">
              {recommendedRegime && card.props.value === recommendedRegime && (
                <Chip
                  text="Recomendado"
                  icon={{ src: 'star-icon.svg', alt: 'star recommendation icon ' }}
                  style={{
                    width: 'max-content',
                    position: 'absolute',
                    zIndex: 1,
                    top: -8,
                    right: 0,
                  }}
                />
              )}
              {card}
            </div>
          )}
        />

        <button data-gtm-track="on" data-gtm-id="create_lead_form_apv" onClick={handleTriggerErrors} type="submit" className="btn btn-secondary text-white py-3 w-100 fw-bold mt-4">
          {repeatSimulation ? 'Volver a simular' : 'Simular'}
        </button>
      </form>

      <SavingsExceededModal
        isOpen={showSavingsExceededModal}
        handleClose={handleCloseSavingsExceededModal}
      />
    </FormProvider>
  );
}
