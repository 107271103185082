import React from 'react';

export function MismatchingProfileDisclaimer() {
  return (
    <div className="alert alert-warning no-icon align-items-center px-6 py-5 fs-base border border-0 rounded-3" role="alert">
      <div className="d-flex gap-6">
        <img width={60} height={60} src="/static/img/illustrations/office-work.svg" alt="" />

        <div>
          <header>
            <h3 className="fs-lg fw-bold text-warning mb-2">Ten en cuenta que:</h3>
          </header>

          <p className="mb-0">
            El objetivo escogido representa un riesgo de inversión
            {' '}
            <span className="fw-bold">diferente al perfil de inversionista del cliente</span>
            .
          </p>
        </div>
      </div>
    </div>
  );
}
