import React from 'react';
import { UploadFilesForSignError } from './uploadDocumentsService';

const RUT_NOT_MATCH_ERROR_MESSAGE = 'Rut not match in document';

type ErrorViewProps = {
  error: UploadFilesForSignError | null;
};

export function ErrorView({ error }: ErrorViewProps) {
  const errorContext = error?.response?.data.context;
  const errorMessage = errorContext?.data.Message === RUT_NOT_MATCH_ERROR_MESSAGE ? (
    <>
      El RUT ingresado
      {' '}
      <span className="fw-bold">no coincide con el del contrato</span>
      , reintenta nuevamente. Recuerda que el RUT ingresado debe ser del  cliente.
    </>
  ) : (
    <>
      Reintenta nuevamente o puedes comunicarte al
      {' '}
      <span className="fw-bold">chat de soporte en teams.</span>
    </>
  );

  return (
    <div className="col-md-10 col-lg-8 col-xl-6">
      <div className="card shadow-drop">
        <div className="card-body p-6 text-center">
          <img
            className="mb-6"
            src="/static/img/illustrations/desktop-error.svg"
            width={120}
            alt=""
          />

          <h1 className="fs-xl text-primary mb-4">
            Error en la carga de archivos
          </h1>

          <p className="text-subtitle fs-lg mb-6">
            {errorMessage}
          </p>

          <div className="row justify-content-center">
            <div className="col-md-6">
              <a className="btn btn-primary w-100" href="/cms/signer/create">
                Reintentar
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
