/* eslint-disable @typescript-eslint/no-floating-promises */
import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect, useState } from 'react';
import { SubmitHandler, useForm, useFormContext } from 'react-hook-form';
import { z } from 'zod';
import { validateLongevityObjective } from '../../../utils/validations';
import { VALIDATION_MESSAGES } from '../../../utils/validationMessages';
import { injectCleanedFieldToForm } from '../../../utils/forms';

export const assetAdvisoryFormSchema = z.object({
  clp_capital_markets_investment: z.number().nonnegative().nullable(),
  uf_capital_markets_investment: z.number().nonnegative().nullable(),
  usd_capital_markets_investment: z.number().nonnegative().nullable(),
  clp_alt_assets: z.number().nonnegative().nullable(),
  uf_alt_assets: z.number().nonnegative().nullable(),
  usd_alt_assets: z.number().nonnegative().nullable(),
  apv: z.number().nonnegative().nullable(),

  clp_pension_plan: z.number().nonnegative().nullable(),
  usd_pension_plan: z.number().nonnegative().nullable(),
  clp_real_state_assets: z.number().nonnegative().nullable(),
  uf_real_state_assets: z.number().nonnegative().nullable(),
  usd_real_state_assets: z.number().nonnegative().nullable(),
  private_company_stakes: z.number().nonnegative().nullable(),
  clp_other_investments: z.number().nonnegative().nullable(),
  uf_other_investments: z.number().nonnegative().nullable(),
  usd_other_investments: z.number().nonnegative().nullable(),

  clp_mortgage_credit: z.number().nonnegative().nullable(),
  uf_mortgage_credit: z.number().nonnegative().nullable(),
  usd_mortgage_credit: z.number().nonnegative().nullable(),
  clp_current_consumer_credit: z.number().nonnegative().nullable(),
  usd_current_consumer_credit: z.number().nonnegative().nullable(),
  clp_other_obligations: z.number().nonnegative().nullable(),
  uf_other_obligations: z.number().nonnegative().nullable(),
  usd_other_obligations: z.number().nonnegative().nullable(),

  clp_incomes: z.number().nonnegative().nullable(),
  uf_incomes: z.number().nonnegative().nullable(),
  usd_incomes: z.number().nonnegative().nullable(),

  objective: z.string(),
  longevity_objective: z.string(),
}).refine(({ objective, longevity_objective }) => (
  validateLongevityObjective(objective, longevity_objective)
), {
  path: ['longevity_objective'],
  message: VALIDATION_MESSAGES.REQUIRED_FIELD,
}).refine(({ objective, longevity_objective, ...nullishValues }) => (
  Object.values(nullishValues).some((v) => v !== null) && objective !== 'OTHER')
);

export type AssetAdvisoryFormValues = z.infer<typeof assetAdvisoryFormSchema>;

const ASSETS_SECTION_FIELDS: Array<keyof AssetAdvisoryFormValues> = [
  'clp_capital_markets_investment',
  'uf_capital_markets_investment',
  'usd_capital_markets_investment',
  'clp_alt_assets',
  'uf_alt_assets',
  'usd_alt_assets',
  'apv',

  'clp_pension_plan',
  'usd_pension_plan',
  'clp_real_state_assets',
  'usd_real_state_assets',
  'private_company_stakes',
  'clp_other_investments',
  'uf_other_investments',
  'usd_other_investments',

  'clp_mortgage_credit',
  'uf_mortgage_credit',
  'usd_mortgage_credit',
  'clp_current_consumer_credit',
  'usd_current_consumer_credit',
  'clp_other_obligations',
  'uf_other_obligations',
  'usd_other_obligations',

  'clp_incomes',
  'uf_incomes',
  'usd_incomes',
];

export function useAssetAdvisoryForm(defaultValues: AssetAdvisoryFormValues) {
  const methods = useForm<AssetAdvisoryFormValues>({
    resolver: zodResolver(assetAdvisoryFormSchema),
    defaultValues,
  });
  const [showObjectivesSection, setShowObjectivesSection] = useState(false);

  const handleAssetsSectionNextStep = async () => {
    try {
      const isClientSectionValid = await methods.trigger(ASSETS_SECTION_FIELDS);

      if (isClientSectionValid && !showObjectivesSection) {
        setShowObjectivesSection(true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const shouldConvertNullishKeyValueToZero = (key: string, value: string | number | null) => (
    ASSETS_SECTION_FIELDS.includes(key as keyof AssetAdvisoryFormValues) && value === null
  );

  // Notice basically all fields are currency fields; so we need to clean the
  // stringified currency value (eg. $10.000.000) and transform it into a base numeric string
  // (10000000) to be able to be processed by backend.
  const injectCleanedValues = (form: HTMLFormElement) => {
    const values = methods.getValues();
    Object
      .entries(values)
      .forEach(([key, value]) => injectCleanedFieldToForm({
        form,
        value: shouldConvertNullishKeyValueToZero(key, value) ? 0 : value,
        name: key,
      }));
  };

  const handleSubmit: SubmitHandler<AssetAdvisoryFormValues> = (data, event) => {
    const form = event?.target as HTMLFormElement;
    injectCleanedValues(form);
    form.submit();
  };

  useEffect(() => {
    const verifyFieldsOnPreload = async () => {
      const isAssetsSectionValid = await methods.trigger(ASSETS_SECTION_FIELDS);

      methods.clearErrors(ASSETS_SECTION_FIELDS);
      const atLeastOneFieldFilled = methods
        .getValues(ASSETS_SECTION_FIELDS).some((v) => v !== null);

      methods.clearErrors(ASSETS_SECTION_FIELDS);
      if (isAssetsSectionValid && atLeastOneFieldFilled) {
        setShowObjectivesSection(true);
      }
    };

    verifyFieldsOnPreload();
  }, []);

  return {
    methods,
    showObjectivesSection,
    handleSubmit,
    handleAssetsSectionNextStep,
    injectCleanedValues,
  };
}

export function useAssetAdvisoryAccordions() {
  const { watch } = useFormContext<AssetAdvisoryFormValues>();

  const {
    apv,
    clp_alt_assets,
    clp_capital_markets_investment,
    clp_other_investments,
    usd_alt_assets,
    uf_alt_assets,
    usd_other_investments,
    uf_real_state_assets,
    uf_other_investments,
    uf_capital_markets_investment,
    usd_capital_markets_investment,
    clp_pension_plan,
    clp_real_state_assets,
    private_company_stakes,
    usd_pension_plan,
    usd_real_state_assets,
    clp_current_consumer_credit,
    usd_current_consumer_credit,
    clp_other_obligations,
    uf_other_obligations,
    usd_other_obligations,
    clp_mortgage_credit,
    uf_mortgage_credit,
    usd_mortgage_credit,
    clp_incomes,
    uf_incomes,
    usd_incomes,
  } = watch();

  const isMarketInvestmentsAccordionFilled = [
    apv,
    clp_alt_assets,
    clp_capital_markets_investment,
    uf_capital_markets_investment,
    usd_alt_assets,
    uf_alt_assets,
    usd_capital_markets_investment,
  ].every((v) => v !== null);

  const isOtherAssetsAccordionFilled = [
    clp_pension_plan,
    clp_real_state_assets,
    private_company_stakes,
    usd_pension_plan,
    usd_real_state_assets,
    uf_real_state_assets,
    clp_other_investments,
    uf_other_investments,
    usd_other_investments,
  ].every((v) => v !== null);

  const isObligationsAccordionFilled = [
    clp_current_consumer_credit,
    usd_current_consumer_credit,
    clp_mortgage_credit,
    uf_mortgage_credit,
    usd_mortgage_credit,
    clp_other_obligations,
    uf_other_obligations,
    usd_other_obligations,
  ].every((v) => v !== null);

  const isIncomesAccordionFilled = [
    clp_incomes,
    uf_incomes,
    usd_incomes,
  ].every((v) => v !== null);

  const allFieldsAreEmpty = [
    apv,
    clp_alt_assets,
    clp_capital_markets_investment,
    clp_other_investments,
    usd_alt_assets,
    uf_alt_assets,
    usd_other_investments,
    uf_real_state_assets,
    uf_other_investments,
    uf_capital_markets_investment,
    usd_capital_markets_investment,
    clp_pension_plan,
    clp_real_state_assets,
    private_company_stakes,
    usd_pension_plan,
    usd_real_state_assets,
    clp_current_consumer_credit,
    usd_current_consumer_credit,
    clp_mortgage_credit,
    uf_mortgage_credit,
    usd_mortgage_credit,
    clp_other_obligations,
    uf_other_obligations,
    usd_other_obligations,
    clp_incomes,
    uf_incomes,
    usd_incomes,
  ].every((v) => !v);

  return {
    isMarketInvestmentsAccordionFilled,
    isOtherAssetsAccordionFilled,
    isObligationsAccordionFilled,
    isIncomesAccordionFilled,
    allFieldsAreEmpty,
  };
}
