import { SubmitHandler, useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { requiredString } from '../../../utils/commonSchemaValidators';
import { VALIDATION_MESSAGES } from '../../../utils/validationMessages';

export const tableForObjective = ['LIQUIDITY', 'LONGEVITY', 'RETIREMENT', 'OTHER'] as const;
export type TableForObjective = typeof tableForObjective[number];

export const riskFormSchema = z.object({
  client_profile: requiredString(),
  profile: requiredString(),
  objective: z.enum(['LIQUIDITY', 'LONGEVITY', 'OTHER']),
  longevity_objective: z.string({
    required_error: VALIDATION_MESSAGES.REQUIRED_FIELD,
    invalid_type_error: VALIDATION_MESSAGES.REQUIRED_FIELD,
  }),
});

export type RiskFormValues = z.infer<typeof riskFormSchema>;

export function useRiskForm(defaultValues: RiskFormValues) {
  const {
    longevity_objective, objective, profile, client_profile,
  } = defaultValues;
  const methods = useForm<RiskFormValues>({
    resolver: zodResolver(riskFormSchema),
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: {
      client_profile,
      longevity_objective,
      objective,
      profile,
    },
  });

  const handleSubmit: SubmitHandler<RiskFormValues> = (_, event) => {
    (event?.target as HTMLFormElement).submit();
  };

  return {
    methods,
    handleSubmit,
  };
}
