import React from 'react';
import {
  Legend,
  Pie,
  PieChart,
} from 'recharts';
import { Formatter } from 'recharts/types/component/DefaultLegendContent';
import { themeColors } from './common';
import { ChartCard } from './components/ChartCard';
import { JsonPropsType } from '../../component-loader';
import { renderPieChartLabel } from './utils';

type CurrencyCompositionChartProps = {
  clpPercent: number;
  usdPercent: number;
  ufPercent: number;
  isRecommendation?: boolean;
};

export function CurrencyCompositionChart(props: JsonPropsType) {
  const {
    clpPercent,
    usdPercent,
    ufPercent,
    isRecommendation,
  } = props.jsonObject as CurrencyCompositionChartProps;

  const data = [
    {
      name: 'Pesos (CLP)',
      value: clpPercent,
      fill: themeColors.secondary,
    },
    {
      name: 'UF',
      value: ufPercent,
      fill: themeColors.info,
    },
    {
      name: 'Dólares',
      value: usdPercent,
      fill: themeColors.primary,
    },
  ];

  const legendFormatter: Formatter = (value) => (
    <span className="fs-xs" style={{ color: themeColors.charts.gray }}>{value}</span>
  );

  return (
    <ChartCard cardBodyClassName="py-5 fs-sm text-primary text-center">
      <PieChart width={240} height={180} style={{ marginInline: 'auto' }}>
        <Pie
          data={data}
          dataKey="value"
          nameKey="name"
          labelLine={false}
          label={renderPieChartLabel}
          blendStroke
          cx="50%"
          cy="50%"
          outerRadius={70}
          isAnimationActive={false}
        />
        <Legend iconSize={18} layout="horizontal" align="center" wrapperStyle={{ bottom: 0 }} formatter={legendFormatter} />
      </PieChart>

      {isRecommendation && <hr className="border-gray-blue-100 mx-auto" style={{ width: 240 }} />}
      <h3 className="fw-normal fs-sm text-primary mt-4 mb-0">
        Composición por
        {' '}
        <span className="fw-bold">moneda</span>
      </h3>
    </ChartCard>
  );
}
