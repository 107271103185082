import React from 'react';
import { Accordion } from 'react-bootstrap';
import clsx from 'clsx';
import { NumericFormatField } from '../../Forms/Fields';
import { AssetAdvisoryFormValues, useAssetAdvisoryAccordions } from './useAssetAdvisoryForm';

export function MarketInvestmentsAccordion() {
  const { isMarketInvestmentsAccordionFilled } = useAssetAdvisoryAccordions();

  return (
    <Accordion.Item className="shadow-none" eventKey="0">
      <Accordion.Button className={clsx(isMarketInvestmentsAccordionFilled && 'bg-success-light')}>
        Inversiones en Mercado Financiero
      </Accordion.Button>

      <Accordion.Body className="d-flex flex-column gap-2">
        <NumericFormatField<AssetAdvisoryFormValues>
          id="clp_capital_markets_investment"
          path="clp_capital_markets_investment"
          label="¿Cuánto tienes en Inversiones de Mercados Financieros nacionales o internacionales?"
          prefix="$ "
          placeholder="$"
        />

        <NumericFormatField<AssetAdvisoryFormValues>
          id="uf_capital_markets_investment"
          path="uf_capital_markets_investment"
          prefix="UF "
          placeholder="UF"
        />

        <NumericFormatField<AssetAdvisoryFormValues>
          id="usd_capital_markets_investment"
          path="usd_capital_markets_investment"
          prefix="USD "
          placeholder="USD"
        />

        <NumericFormatField<AssetAdvisoryFormValues>
          id="clp_alt_assets"
          path="clp_alt_assets"
          label="¿Cuánto tienes en Activos Alternativos nacionales o internacionales?"
          prefix="$ "
          placeholder="$"
        />

        <NumericFormatField<AssetAdvisoryFormValues>
          id="uf_alt_assets"
          path="uf_alt_assets"
          prefix="UF "
          placeholder="UF"
        />

        <NumericFormatField<AssetAdvisoryFormValues>
          id="usd_alt_assets"
          path="usd_alt_assets"
          prefix="USD "
          placeholder="USD"
        />

        <NumericFormatField<AssetAdvisoryFormValues>
          id="apv"
          path="apv"
          label="¿Cuánto tienes en APV y/o Depósitos convenidos?"
          prefix="$ "
          placeholder="$"
        />
      </Accordion.Body>
    </Accordion.Item>
  );
}
