import React from 'react';
import { DateTime } from 'luxon';
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { useQuery } from '@tanstack/react-query';
import { formatMoney } from '../../utils/formatters';
import { TickProps } from './types';
import { themeColors } from './common';
import { MoneyTooltip } from './components/MoneyTooltip';
import { ChartCard } from './components/ChartCard';
import { LoadingSpinner } from '../common';
import { getRecommendationChartData } from './services';
import { JsonPropsType } from '../../component-loader';
import { WithQueryClient } from '../common/WithQueryClient';
import { ErrorMessage } from './components/ErrorMessage';
import { InvestmentProfileValues } from '../../utils/constants';

const mainColorByProfile: Record<InvestmentProfileValues, string> = {
  CONSERVATIVE: themeColors.charts.beige,
  BALANCED: themeColors.charts.beige,
  AGGRESSIVE: themeColors.redDark,
};

const lightColorByProfile: Record<InvestmentProfileValues, string> = {
  CONSERVATIVE: themeColors.charts.beigeLight,
  BALANCED: themeColors.charts.beigeLight,
  AGGRESSIVE: themeColors.redMiddle,
};

const roundDomain = (value: number) => [
  Math.floor(value / 1_000) * 1_000,
];

function MonthTick({
  index, payload, x, y, dataAmount,
}: TickProps<string>) {
  const date = DateTime.fromFormat(payload.value, 'yyyy-MM-dd');
  const monthName = date.setLocale('es').toFormat('MMM');
  const { year, month, day } = date;
  const isJanuary = month === 1;
  const isDisplayableMonth = (month - 1) % 3 === 0;
  const isLastEntry = index === dataAmount! - 1;

  // Label should be displayed every three months, at first day of every month
  // Also, if last entry of the graph data doesn't belong to a displayable month, it should be shown
  if ((isDisplayableMonth && day === 1) || (isLastEntry && !isDisplayableMonth)) {
    return (
      <g transform={`translate(${x},${y})`}>
        <text
          className="text-capitalize"
          x={0}
          y={0}
          dy={16}
          dx={isLastEntry ? 8 : 0}
          textAnchor="middle"
          fill={themeColors.subtitle}
        >
          {monthName}
        </text>
        {isJanuary && (
        <text
          x={2}
          y={20}
          dy={16}
          className="fs-sm fw-bold"
          textAnchor="middle"
          fill={themeColors.gray[500]}
        >
          {year}
        </text>
        )}
      </g>
    );
  }

  return null;
}

interface ExpectedReturnsChartProps {
  profile?: InvestmentProfileValues
  isDownload: boolean
  productId: number
  url: string
}

function ExpectedReturnsChart({
  productId, url, isDownload, profile = 'BALANCED',
}: ExpectedReturnsChartProps) {
  const {
    data: response, isLoading, isSuccess, isError,
  } = useQuery({
    queryFn: () => getRecommendationChartData({ productId, profile }),
    queryKey: ['GET_EXPECTED_RETURNS', profile, productId],
    refetchOnWindowFocus: false,
  });

  const dataMaxTreshold = 2_000;
  const chartHeight = 250;
  const chartWidthForDownload = 560;

  const chartMargins = {
    top: 24,
    right: 36,
    left: 36,
  };

  const legendPosition = {
    bottom: -26,
    right: 85,
  };

  return (
    <ChartCard
      headerText="Evolución de una inversión de $100.000"
      textLink="Ir a la ficha"
      isDownload={isDownload}
      url={url}
    >
      <div
        className="mb-4"
        style={{
          height: chartHeight,
          width: isDownload ? chartWidthForDownload : '100%',
        }}
      >
        {isError && <ErrorMessage />}
        {isLoading && <LoadingSpinner className="h-100" size={108} borderWidth={4} color="primary" />}
        {isSuccess && response && (
          <ResponsiveContainer width="100%" height="100%">
            <LineChart
              margin={chartMargins}
              data={response.data.graph_data}
            >
              <CartesianGrid vertical={false} />
              <XAxis
                interval={0}
                // Distance between tick labels and x axis
                tickMargin={6}
                stroke={themeColors.gray[400]}
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                // `Recharts` automatically injects `props` into custom tick component;
                // and we manually type them in the `MonthTick` definition;
                // so we omit this compilation check for avoid warnings about `any` types.
                tick={(props: TickProps<string>) => MonthTick({
                  ...props,
                  dataAmount: response.data.graph_data.length,
                })}
                dataKey="date"
                axisLine={false}
                tickLine={false}
              />
              <YAxis
                domain={[
                  (dataMin: number) => roundDomain(dataMin),
                  (dataMax: number) => roundDomain(dataMax + dataMaxTreshold)]}
                tickLine={false}
                tickFormatter={formatMoney}
                tick={{ fill: themeColors.subtitle }}
                stroke={themeColors.gray[400]}
              />
              <Line
                name="Serie clásica"
                dot={false}
                type="monotone"
                dataKey="value"
                stroke={mainColorByProfile[profile]}
              />
              <Legend
                layout="horizontal"
                wrapperStyle={legendPosition}
                iconType="square"
                align="right"
              />
              <Tooltip
                cursor={false}
                content={(
                  <MoneyTooltip
                    background={lightColorByProfile[profile]}
                    color={mainColorByProfile[profile]}
                  />
                  )}
              />
            </LineChart>
          </ResponsiveContainer>
        )}
      </div>
    </ChartCard>
  );
}

interface ExpectedReturnsChartWrapperProps {
  profile?: InvestmentProfileValues // We trust in backend!!!
  productId: number
  isDownload: boolean
  url: string
}

export function ExpectedReturnsChartWrapper(props: JsonPropsType) {
  const {
    profile, productId, url, isDownload,
  } = props.jsonObject as ExpectedReturnsChartWrapperProps;

  return (
    <WithQueryClient>
      <ExpectedReturnsChart
        profile={profile}
        productId={productId}
        url={url}
        isDownload={isDownload}
      />
    </WithQueryClient>
  );
}
