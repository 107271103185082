import React from 'react';
import { PieLabelRenderProps } from 'recharts';

export const renderPieChartLabel = ({
  cx,
  cy,
  midAngle,
  outerRadius,
  percent,
  index,
  innerRadius = 0,
}: PieLabelRenderProps, omitValues: number[] = []) => {
  if (!cx || !cy || !percent || omitValues.includes(index as number)) return null;

  const RADIAN = Math.PI / 180;
  const radius = Number(innerRadius) + (Number(outerRadius) - Number(innerRadius)) * 0.5;
  const x = Number(cx) + radius * Math.cos(-midAngle * RADIAN);
  const y = Number(cy) + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor="middle"
      dominantBaseline="central"
      fontWeight="bold"
      fontSize={10}
    >
      {`${(percent * 100).toFixed(1)}%`}
    </text>
  );
};
