import { api, attachAuthOptions } from '../../utils/api';

// TODO: update endpoints when backend is implemented
const SET_FAVORITE_ENDPOINT = (id: number) => `favorites/${id}`;
const UNSET_FAVORITE_ENDPOINT = (id: number) => `favorites/${id}/unfavorite`;

/**
 * Marks or unmarks an item as favorite.
 * @param id The ID of the item.
 * @param setAsFavorite `true` to mark as favorite, `false` to remove favorite.
 */
export async function toggleFavorite(id: number, setAsFavorite: boolean) {
  const endpoint = setAsFavorite ? SET_FAVORITE_ENDPOINT(id) : UNSET_FAVORITE_ENDPOINT(id);

  return api.post(endpoint, {}, attachAuthOptions());
}
