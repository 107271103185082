/* eslint-disable @typescript-eslint/no-misused-promises */
import { toggleFavorite } from './favorites-service';

const FAVORITE_ACTIVE_ICON = 'favorite-active.svg';
const FAVORITE_INACTIVE_ICON = 'favorite-inactive.svg';

/**
 * Updates the button's state to reflect whether the item is marked as a favorite.
 *
 * @param button The button element that toggles the favorite state.
 * @param setAsFavorite `true` to mark as favorite, `false` to unmark it.
 */
function toggleFavoriteButton(button: HTMLButtonElement, setAsFavorite?: boolean) {
  const image = button.querySelector<HTMLImageElement>('img')!;

  /* eslint-disable no-param-reassign */
  button.ariaLabel = setAsFavorite ? 'Desmarcar como favorito' : 'Marcar como favorito';
  button.dataset.favorite = String(setAsFavorite);
  /* eslint-enable no-param-reassign */
  image.src = setAsFavorite
    ? image.src.replace(FAVORITE_INACTIVE_ICON, FAVORITE_ACTIVE_ICON)
    : image.src.replace(FAVORITE_ACTIVE_ICON, FAVORITE_INACTIVE_ICON);
}

/**
 * Initializes the favorite toggle button by adding a click event listener.
 *
 * @param button The button element to initialize.
 */
function initToggleFavoriteButton(button: HTMLButtonElement) {
  button.addEventListener('click', async (event) => {
    event.preventDefault();

    const id = Number(button.dataset.id);
    const isFavorite = button.dataset.favorite === 'true';

    try {
      await toggleFavorite(id, !isFavorite);
      toggleFavoriteButton(button, !isFavorite);
    } catch (error) {
      console.error(error);
    }
  });
}

// Initialize toggle favorite
window.addEventListener('DOMContentLoaded', () => {
  const toggleFavoriteButtons = document.querySelectorAll<HTMLButtonElement>('[data-toggle="favorite"]');
  toggleFavoriteButtons.forEach(initToggleFavoriteButton);
});
