import React from 'react';
import { Accordion } from 'react-bootstrap';
import clsx from 'clsx';
import { NumericFormatField } from '../../Forms/Fields';
import { AssetAdvisoryFormValues, useAssetAdvisoryAccordions } from './useAssetAdvisoryForm';

export function IncomesAccordion() {
  const {
    isIncomesAccordionFilled,
  } = useAssetAdvisoryAccordions();

  return (
    <Accordion.Item className="shadow-none" eventKey="3">
      <Accordion.Button
        className={clsx(isIncomesAccordionFilled && 'bg-success-light')}
      >
        Ingresos
      </Accordion.Button>

      <Accordion.Body
        className="d-flex flex-column gap-2"
      >
        <NumericFormatField<AssetAdvisoryFormValues>
          id="clp_incomes"
          path="clp_incomes"
          label="¿Con cuánto cuentas en ingresos recurrentes?"
          prefix="$ "
          placeholder="$"
          helpPopover={{
            id: 'other-obligations-help-popover',
            width: 362,
            renderContent: () => (
              <div className="d-flex align-items-start gap-2 p-1">
                <img src="/static/img/icons/help-light.svg" width={48} alt="" />

                <section>
                  <header>
                    <h6 className="fs-base fw-bold text-white mb-1">Ingresos recurrentes</h6>
                  </header>

                  <div className="text-white">
                    Considera ingresos mensuales más ingresos financieros líquidos.
                  </div>
                </section>
              </div>
            ),
          }}
        />

        <NumericFormatField<AssetAdvisoryFormValues>
          id="uf_incomes"
          path="uf_incomes"
          prefix="UF "
          placeholder="UF"
        />

        <NumericFormatField<AssetAdvisoryFormValues>
          id="usd_incomes"
          path="usd_incomes"
          prefix="USD "
          placeholder="USD"
        />
      </Accordion.Body>
    </Accordion.Item>
  );
}
