import React from 'react';
import {
  Cell, Pie, PieChart, PieLabelRenderProps, ResponsiveContainer,
} from 'recharts';
import { themeColors } from './common';
import { renderPieChartLabel } from './utils';
import { JsonPropsType } from '../../component-loader';
import { ChartCard } from './components/ChartCard';

interface ChartSectionData {
  label?: string
  hidePercentageDisplay?: boolean
  value: number
  color: string
}

interface DonutChartProps {
  data: ChartSectionData[]
  legendHeader?: string
}

function DonutChart({ data, legendHeader }: DonutChartProps) {
  const sectionsToOmit = data
    .map((({ hidePercentageDisplay }, index) => (hidePercentageDisplay ? index : -1)));

  return (
    <div className="d-flex g-4 align-items-center mb-0">
      <div className="col-7 col-lg-8 col-xl-7">
        <ResponsiveContainer width="100%" height="100%" minHeight={180}>
          <PieChart>
            <Pie
              isAnimationActive={false}
              blendStroke
              data={data}
              cx="50%"
              cy="50%"
              innerRadius={30}
              outerRadius={70}
              dataKey="value"
              label={(labelProps: PieLabelRenderProps) => renderPieChartLabel(
                labelProps, sectionsToOmit
              )}
              labelLine={false}
            >
              {data.map((entry) => (
                <Cell key={`cell-${entry.value}`} fill={entry.color} />
              ))}
            </Pie>
          </PieChart>
        </ResponsiveContainer>
      </div>

      <div className="col-5 col-lg-4 col-xl-5 ">
        {legendHeader && (
          <header>
            <h4 className="mb-2 text-primary fs-xs">{legendHeader}</h4>
          </header>
        )}
        <ul className="d-flex flex-column gap-2 fs-6 px-0 list-unstyled">
          {data.filter((entry) => Boolean(entry.label)).map((entry) => (
            <li
              className="d-flex align-items-center gap-2"
              key={entry.value}
              style={{
                color: entry.color,
                textAlign: 'start',
              }}
            >
              <div
                className="list-dot"
                style={{ backgroundColor: entry.color }}
              />
              <span className="text-dark fs-xs">
                {entry.label}
              </span>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

interface VerticalStackBarChartProps {
  data: ChartSectionData[]
}

function VerticalStackBarChart({ data }: VerticalStackBarChartProps) {
  return (
    <div className="d-flex gap-4 mb-0 justify-content-center">
      <div className="d-flex flex-column" style={{ height: 150, width: 80 }}>
        {data.map((entry, index) => {
          const isFirst = index === 0;
          const isLast = index === data.length - 1;
          return (
            <div
              className="d-flex align-items-center justify-content-center text-white fw-bold fs-2xs"
              style={{
                height: `${entry.value}%`,
                backgroundColor: entry.color,
                borderTopLeftRadius: isFirst ? 8 : 0,
                borderTopRightRadius: isFirst ? 8 : 0,
                borderBottomLeftRadius: isLast ? 8 : 0,
                borderBottomRightRadius: isLast ? 8 : 0,
              }}
            >
              {entry.value}
              %
            </div>
          );
        })}
      </div>

      <ul className="d-flex flex-column gap-2 fs-6 px-0 list-unstyled mt-3">
        {data.filter((entry) => Boolean(entry.label)).map((entry) => (
          <li
            className="d-flex align-items-center gap-2"
            key={entry.value}
            style={{
              color: entry.color,
              textAlign: 'start',
            }}
          >
            <div
              className="list-dot"
              style={{ backgroundColor: entry.color }}
            />
            <span className="text-dark fs-xs">
              {entry.label}
            </span>
          </li>
        ))}
      </ul>
    </div>
  );
}

type AssetDistributionChartProps = {
  financialMarketsPercent: number;
  alternativeAssetsPercent: number;
  realEstateAssetsPercent: number;
  otherAssetsPercent: number;
  isRecommendation?: boolean;
};

export function AssetDistributionChart(props: JsonPropsType) {
  const {
    alternativeAssetsPercent,
    financialMarketsPercent,
    otherAssetsPercent,
    realEstateAssetsPercent,
    isRecommendation,
  } = props.jsonObject as AssetDistributionChartProps;

  const data = [
    {
      label: 'Activos en Mercados Financieros',
      value: financialMarketsPercent,
      color: themeColors.secondary,
    },
    {
      label: 'Activos alternativos',
      value: alternativeAssetsPercent,
      color: themeColors.primary,
    },
    {
      label: 'Activos Inmobiliarios',
      value: realEstateAssetsPercent,
      color: themeColors.info,
    },
    {
      label: 'Otros Activos',
      value: otherAssetsPercent,
      color: themeColors.darkSecondary,
    },
  ];

  return (
    <ChartCard cardBodyClassName="py-5 fs-sm text-primary text-center">
      <DonutChart data={data} />

      {isRecommendation && <hr className="border-gray-blue-100 mx-auto" style={{ width: 240 }} />}
      <h3 className="fw-normal fs-sm text-primary mt-4 mb-0">
        {'Composición de '}
        <span className="fw-bold">activos</span>
      </h3>
    </ChartCard>
  );
}

type RecommendedAssetDistributionChartProps = {
  financialMarketsPercent: number;
  alternativeAssetsPercent: number;
  fixedIncomePercent: number;
  variableIncomePercent: number;
  otherAssetsPercent: number;
  isRecommendation?: boolean;
};

export function RecommendedAssetDistributionChart(props: JsonPropsType) {
  const {
    alternativeAssetsPercent,
    financialMarketsPercent,
    otherAssetsPercent,
    fixedIncomePercent,
    variableIncomePercent,
    isRecommendation,
  } = props.jsonObject as RecommendedAssetDistributionChartProps;

  const donutData = [
    {
      label: 'Activos en Mercados Financieros',
      value: financialMarketsPercent,
      color: themeColors.secondary,
    },
    {
      label: 'Activos alternativos',
      value: alternativeAssetsPercent,
      color: themeColors.primary,
    },
    {
      hidePercentageDisplay: true,
      value: 100 - (financialMarketsPercent + alternativeAssetsPercent),
      color: themeColors.grayBlue[200],
    },
  ];

  const barData = [
    {
      label: 'Renta Fija',
      value: fixedIncomePercent,
      color: themeColors.info,
    },
    {
      label: 'Renta Variable',
      value: variableIncomePercent,
      color: themeColors.primary,
    },
    {
      label: 'Activos Alternativos',
      value: otherAssetsPercent,
      color: themeColors.secondary,
    },
  ];

  return (
    <ChartCard cardBodyClassName="py-5 fs-sm text-primary text-center">
      <div className="row justify-content-center align-items-center">
        <div className="col-12 col-md-7">
          <DonutChart data={donutData} legendHeader="Nuestra recomendación se basa en:" />
        </div>
        <div className="col-12 col-md-5">
          <VerticalStackBarChart data={barData} />
        </div>
      </div>

      {isRecommendation && <hr className="border-gray-blue-100 mx-auto" style={{ width: 240 }} />}
      <h3 className="fw-normal fs-sm text-primary mt-4 mb-0">
        {'Composición de '}
        <span className="fw-bold">activos</span>
      </h3>
    </ChartCard>
  );
}
