import React from 'react';
import { ChartCard } from './components/ChartCard';
import { JsonPropsType } from '../../component-loader';
import { formatMoney } from '../../utils/formatters';

type AssetBalanceChartProps = {
  actives: number
  pasives: number
  assets: number
  isRecommendation: boolean
};

export function AssetBalanceChart(props: JsonPropsType) {
  const {
    actives,
    assets,
    pasives,
  } = props.jsonObject as AssetBalanceChartProps;

  const pasivesAndAssetsTotal = pasives + assets;
  const pasivesPercent = (pasives * 100) / pasivesAndAssetsTotal;
  const assetsPercent = (assets * 100) / pasivesAndAssetsTotal;

  return (
    <ChartCard cardBodyClassName="py-5 fs-sm text-primary text-center">
      <div className="d-flex gap-2 flex-wrap mb-2 justify-content-center" style={{ height: 188 }}>
        <div className="h-100 fs-2xs p-4 d-flex rounded align-items-center justify-content-center bg-info text-center text-white fw-bold">
          {formatMoney(actives)}
          <br />
          Activos
        </div>

        <div className="h-100 d-flex flex-column gap-2 justify-content-center">
          <div style={{ height: `${pasivesPercent}%` }} className="fs-2xs p-4 d-flex bg-secondary text-center text-white fw-bold rounded align-items-center justify-content-center">
            {formatMoney(pasives)}
            <br />
            Pasivos
          </div>

          <div style={{ height: `${assetsPercent}%` }} className="fs-2xs p-4 d-flex bg-primary-active text-center text-white fw-bold rounded align-items-center justify-content-center">
            {formatMoney(assets)}
            <br />
            Patrimonio
          </div>
        </div>
      </div>

      <h3 className="fw-normal fs-sm text-primary mb-0">
        Balance
        {' '}
        <span className="fw-bold">patrimonial</span>
      </h3>
    </ChartCard>
  );
}
